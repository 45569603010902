import PrimaryBlackButton from "../components/elements/buttons/PrimaryBlackButton";
import Navigation from "../components/modules/navigation/Navigation";
import useHeadComponent from "../hooks/useHeadComponent";
import getTranslatedText from "../libs/getTranslatedText";
import { CircleAlert } from "akar-icons";
import { useRouter } from "next/router";

export default function Custom404() {
  const localeLinks = {
    en: "/en",
    ru: "/ru",
    es: "/es",
    tr: "/",
  };

  const Head = useHeadComponent({
    title: "404",
    translationKey: "404Page",
    description:
      "Tarım ürünlerine ve şirketlere, yurtiçi ve yurtdışı tarım fuarlarına, zirai mücadele yöntemlerine, yetiştiricilik hakkında binlerce içeriğe kolayca ulaş.",
    alternateList: [],
    dynamic: false,
    noindex: true,
    noBrand: true,
    ogImage: null,
    ogType: "website",
    schemaMarkup: null,
  });

  const { locale } = useRouter();

  return (
    <div>
      <Navigation localeLinks={localeLinks} />
      <div className="flex flex-col items-center justify-center w-full h-screen -mt-[75px] bg-gradient-to-r from-rose-100 to-teal-100">
        <CircleAlert strokeWidth={2} size={24} />
        <h1 className="font-bold text-8xl">404</h1>
        <p className="max-w-sm mt-6 text-sm text-center text-gray-600">
          {getTranslatedText(
            locale,
            "Aradığınız sayfa kaldırılmış ya da yanlış bir adrese ulaşmaya çalıştınız. Bir hata olduğunu düşünüyorsanız lütfen bize aşağıdakidestek kutusundan yazın.",
            "utilities.404Page.message"
          )}
        </p>
        <div className="mt-8">
          <PrimaryBlackButton type="link" href="/">
            {getTranslatedText(
              locale,
              " Ana Sayfaya Dön",
              "utilities.404Page.button"
            )}
          </PrimaryBlackButton>
        </div>
      </div>
      {Head}
    </div>
  );
}
